import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-fetch-client';
import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ServiceConsumptionThreadService } from 'services/service-consumption-thread-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ThreadList {

  @bindable private service: Models.Service;
  @bindable private net: Models.Net;
  @bindable private locked;
  @bindable private invoiceConsumptionThreadsStatus: string;

  @bindable ({ defaultBindingMode: bindingMode.fromView }) private editFormVisible;
  @bindable ({ defaultBindingMode: bindingMode.fromView }) private newFormVisible;

  private threads: Array<Models.ServiceConsumptionThread>;
  private originals: any = {};

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionThreadService: ServiceConsumptionThreadService
  ) { }

  private attached() {

    this.getConsumptionThreads();

    this.eventAggregator.subscribe('serviceConsumptionThreadsListReset', (value) => {
      this.getConsumptionThreads();
    });

    this.eventAggregator.subscribe('serviceConsumptionThreadsListCancel', (value) => {
      this.threads = JSON.parse(JSON.stringify(this.originals.threads));
    });

  }

  private getConsumptionThreads() {
    this.serviceConsumptionThreadService
    .getAll('?$filter=ServiceId eq ' + this.service.Id + ' &$expand=ThreadType,NylonType,MeshSize,NettingTypeService($expand=Product)')
    .then((res) => {
      this.threads = res;
      this.originals.threads = JSON.parse(JSON.stringify(res));
      const invoiceConsumptionThreadsStatusList = this.threads.map((x) => x.ServiceInvoiceId ? true : false);
      this.invoiceConsumptionThreadsStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionThreadsStatusList);
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private deleteThread(thread) {
    this.serviceConsumptionThreadService
    .delete(thread.Id)
    .then((res) => {
      this.eventAggregator.publish('serviceConsumptionThreadsListReset', 0);
      this.toastService.showSuccess('service.consumptionThreadDeleted');
    })
    .catch((err) => this.errorService.handleError(err));
  }

}
