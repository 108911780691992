import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-fetch-client';
import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ServiceConsumptionNettingService } from 'services/service-consumption-netting-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class NettingList {

  @bindable private service: Models.Service;
  @bindable private net: Models.Net;
  @bindable private formVisible;
  @bindable private locked;
  @bindable private invoiceConsumptionNettingStatus: string;

  private nettings: Array<Models.ServiceConsumptionNetting>;
  @bindable ({ defaultBindingMode: bindingMode.fromView }) private editFormVisible;
  @bindable ({ defaultBindingMode: bindingMode.fromView }) private newFormVisible;
  private originals: any = {};

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionNettingService: ServiceConsumptionNettingService
  ) { }

  private attached() {

    this.getConsumptionNettings();

    this.eventAggregator.subscribe('serviceConsumptionNettingsListReset', (value) => {
      this.getConsumptionNettings();
    });

    this.eventAggregator.subscribe('serviceConsumptionNettingsListCancel', (value) => {
      this.nettings = JSON.parse(JSON.stringify(this.originals.nettings));
    });

  }

  private getConsumptionNettings() {
    this.serviceConsumptionNettingService
    .getAll('?$filter=ServiceId eq ' + this.service.Id + ' &$expand=ThreadType,NylonType,MeshSize,NettingType($expand=Product)')
    .then((res) => {
      this.nettings = res;
      this.originals.nettings = JSON.parse(JSON.stringify(res));
      const invoiceConsumptionNettingStatusList = this.nettings.map((x) => x.ServiceInvoiceId ? true : false);
      this.invoiceConsumptionNettingStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionNettingStatusList)
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private deleteNetting(netting) {
    this.serviceConsumptionNettingService
    .delete(netting.Id)
    .then((res) => {
      this.eventAggregator.publish('serviceConsumptionNettingsListReset', 0);
      this.toastService.showSuccess('service.consumptionNettingDeleted');
    })
    .catch((err) => this.errorService.handleError(err));
  }

}
