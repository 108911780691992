import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Models } from 'models/core';
import { RopeTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ServiceConsumptionRopeService } from 'services/service-consumption-rope-service';
import { ToastService } from 'services/toast-service';
import { RopeTypeService as RopeTypeServiceModel } from 'models';

@autoinject
export class RopeInlineEdit {
  @bindable private service: Models.Service;
  @bindable private rope: Models.ServiceConsumptionRope;
  @bindable private editFormVisible;
  @bindable private context;

  private currentRopeType: Models.RopeType;
  private validationController: ValidationController;
  private currentRopeTypeService: RopeTypeServiceModel;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionRopeService: ServiceConsumptionRopeService,
    protected ropePlacementService: RopePlacementService,
    private ropeTypeService: RopeTypeService,
    private ropeTypeServiceService: RopeTypeServiceService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceConsumptionRope) {
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure(this.service.IsInvoicingCompatible ? 'RopeTypeServiceId' : 'RopeTypeId')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('Consumption')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(0)
        .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .ensure('ProductionNumber')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(item);
    } else {
      ValidationRules.ensure('ProductionNumber').required().withMessage(this.t.tr('general.requiredField')).on(item);
    }
  }

  private async validate(item: Models.ServiceConsumptionRope) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: item })).valid;
  }

  protected async saveRope() {
    if (!(await this.validate(this.rope))) {
      return;
    }
    // delete expands
    delete this.rope.RopePlacement;
    delete this.rope.RopeType;
    delete this.rope.RopeDimension;
    delete this.rope.RopeTypeService;

    this.serviceConsumptionRopeService
      .put(this.rope, this.rope.Id)
      .then(() => {
        this.eventAggregator.publish('serviceConsumptionRopesListReset', 0);
        this.toastService.showSuccess('service.consumptionRopeSaved');
        this.editFormVisible = false;
      })

      .catch((err) => this.errorService.handleError(err));
  }

  private ropeTypeChanged(event) {
    if (!event.detail.value || this.rope.RopeTypeId === event.detail.value) {
      return;
    }
    this.rope.RopeTypeId = event.detail.value;
    this.ropeTypeService
      .get(this.rope.RopeTypeId + '?$expand=NavisionProduct')
      .then((res) => {
        this.currentRopeType = res;
        if (this.currentRopeType.NavisionProduct) {
          this.rope.ActualBreakingStrength = this.currentRopeType.NavisionProduct.MinBreakingStrength;
        }
        this.rope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async ropeTypeServiceChanged(event) {
    try {
      if (!event.detail.value || this.rope?.RopeTypeServiceId === event.detail.value) {
        return;
      }
      this.rope.RopeTypeServiceId = event.detail.value;
      const ropeType = await this.ropeTypeServiceService.get(this.rope.RopeTypeServiceId + '?$expand=Product');
      this.currentRopeTypeService = ropeType;
      if (this.currentRopeTypeService.Product) {
        this.rope.ActualBreakingStrength = this.currentRopeTypeService.Product.MinBreakingStrength;
      }
      this.rope.RopeDimensionId = this.currentRopeTypeService.RopeDimensionId;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getRopeTypeServiceService() {
    const ropeTypeServiceServices = await this.ropeTypeServiceService.getAll('?$expand=Product');

    ropeTypeServiceServices.forEach((ropeTypeServiceService) => {
      if(ropeTypeServiceService.Product){
        ropeTypeServiceService.Name = ropeTypeServiceService.Name + " (" + ropeTypeServiceService.Product.ArticleNo + ")"
      }
    })
    
    return ropeTypeServiceServices
  }

  protected async getRopeTypeService() {
    const ropeTypeServices = await this.ropeTypeService.getAll();

    ropeTypeServices.forEach((ropeTypeService) => {
      if(ropeTypeService.NavisionProduct){
        ropeTypeService.Name = ropeTypeService.Name + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
        ropeTypeService.NameEn = ropeTypeService.NameEn + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
        ropeTypeService.NameEs = ropeTypeService.NameEs + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
      }
    })
    
    return ropeTypeServices
  }

  private cancel() {
    this.editFormVisible = null;
    this.eventAggregator.publish('serviceConsumptionRopesListCancel', 0);
  }
}
