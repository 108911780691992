import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Models } from 'models/core';
import { RopeTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ServiceConsumptionRopeService } from 'services/service-consumption-rope-service';
import { ToastService } from 'services/toast-service';
import { RopeTypeService as RopeTypeServiceModel } from 'models';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';

@autoinject
export class RopeInlineNew {
  @bindable private service: Models.Service;
  @bindable private minimumBreakingStrength: number;
  @bindable private newFormVisible;
  @bindable private context;

  private rope: Models.ServiceConsumptionRope = new Models.ServiceConsumptionRope();

  private currentRopeType: Models.RopeType;
  private currentRopePlacementId: number;

  private currentRopeTypeService: RopeTypeServiceModel;

  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionRopeService: ServiceConsumptionRopeService,
    private ropePlacementService: RopePlacementService,
    private ropeTypeService: RopeTypeService,
    private ropeTypeServiceService: RopeTypeServiceService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceConsumptionRope) {
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure(this.service.IsInvoicingCompatible ? 'RopeTypeServiceId' : 'RopeTypeId')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('Consumption')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(0)
        .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .ensure('ProductionNumber')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(item);
    } else {
      ValidationRules.ensure('ProductionNumber').required().withMessage(this.t.tr('general.requiredField')).on(item);
    }
  }

  private async validate(item: Models.ServiceConsumptionRope) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: item })).valid;
  }

  private async getRopePlacements() {
    const allPlacements = await this.ropePlacementService.getAllCached();

    // Do not remove spaghettirope
    return allPlacements.filter((p) => p.Id != 14);
  }

  protected async createRope() {
    if (!(await this.validate(this.rope))) {
      return;
    }

    if (this.context == 'spaghetti') {
      this.rope.IsSpaghetti = true;
      this.rope.RopePlacementId = 14;
    }

    this.rope.ServiceId = this.service.Id;
    this.rope.MinimumBreakingLoad = this.minimumBreakingStrength;
    this.rope.RopePlacementId = this.currentRopePlacementId;
    delete this.rope.RopeTypeService;

    this.serviceConsumptionRopeService
      .post(this.rope)
      .then(() => {
        this.rope = null;
        this.newFormVisible = false;
        this.eventAggregator.publish('serviceConsumptionRopesListReset', 0);
        this.toastService.showSuccess('service.consumptionRopeCreated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected ropePlacementChanged(event) {
    this.currentRopePlacementId = event.detail.value;
  }

  protected async ropeTypeChanged(event) {
    try {
      if (!event.detail.value || this.rope?.RopeTypeId === event.detail.value) {
        return;
      }
      this.rope.RopeTypeId = event.detail.value;
      const ropeType = await this.ropeTypeService.get(this.rope.RopeTypeId + '?$expand=NavisionProduct');
      this.currentRopeType = ropeType;
      if (this.currentRopeType.NavisionProduct) {
        this.rope.ActualBreakingStrength = this.currentRopeType.NavisionProduct.MinBreakingStrength;
      }
      this.rope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async ropeTypeServiceChanged(event) {
    try {
      if (!event.detail.value || this.rope?.RopeTypeServiceId === event.detail.value) {
        return;
      }
      this.rope.RopeTypeServiceId = event.detail.value;
      const ropeType = await this.ropeTypeServiceService.get(this.rope.RopeTypeServiceId + '?$expand=Product');
      this.currentRopeTypeService = ropeType;
      if (this.currentRopeTypeService.Product) {
        this.rope.ActualBreakingStrength = this.currentRopeTypeService.Product.MinBreakingStrength;
      }
      this.rope.RopeDimensionId = this.currentRopeTypeService.RopeDimensionId;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  
  protected async getRopeTypeServiceService() {
    const ropeTypeServiceServices = await this.ropeTypeServiceService.getAll('?$expand=Product');

    ropeTypeServiceServices.forEach((ropeTypeServiceService) => {
      if(ropeTypeServiceService.Product){
        ropeTypeServiceService.Name = ropeTypeServiceService.Name + " (" + ropeTypeServiceService.Product.ArticleNo + ")"
      }
    })
    
    return ropeTypeServiceServices
  }

  protected async getRopeTypeService() {
    const ropeTypeServices = await this.ropeTypeService.getAll();

    ropeTypeServices.forEach((ropeTypeService) => {
      if(ropeTypeService.NavisionProduct){
        ropeTypeService.Name = ropeTypeService.Name + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
        ropeTypeService.NameEn = ropeTypeService.NameEn + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
        ropeTypeService.NameEs = ropeTypeService.NameEs + " (" + ropeTypeService.NavisionProduct.ArticleNo + ")";
      }
    })
    
    return ropeTypeServices
  }

  protected cancel() {
    this.newFormVisible = null;
    this.eventAggregator.publish('serviceConsumptionRopesListCancel', 0);
  }
}
