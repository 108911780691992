import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { ReportService } from './../../services/report-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class ServiceNotes {
  private reportContent: string;
  private serviceId: number;
  private generateServiceNotesReportProgress: boolean = false;  

  constructor(private controller: DialogController, 
    private reportService: ReportService, 
    private toastService: ToastService,
    private errorService: ErrorService) {
  }

  public activate(model) {    
    this.reportContent = model.reportContent;   
    this.serviceId = model.serviceId;
  }

  private generateServiceNotesReport() {
    if (this.generateServiceNotesReportProgress) { return; }

    this.generateServiceNotesReportProgress = true;

    this.reportService.getServiceNotesReport(this.serviceId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.generateServiceNotesReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceNotesReportProgress = false;
      });    
  }
}
