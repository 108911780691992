import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { InvoiceBadge } from 'components/badge/invoice-badge';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceConsumptionLoopService } from 'services/service-consumption-loop-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class LoopList {
  @bindable private service: Models.Service;
  @bindable private net: Models.Net;
  @bindable private locked;
  @bindable private invoiceConsumptionLoopStatus: string;

  private loops: Array<Models.ServiceConsumptionLoop>;
  private editFormVisible;
  private newFormVisible;
  private originals: any = {};

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionLoopService: ServiceConsumptionLoopService
  ) {}

  private attached() {
    this.getConsumptionLoops();

    this.eventAggregator.subscribe('serviceConsumptionLoopsListReset', (value) => {
      this.getConsumptionLoops();
    });

    this.eventAggregator.subscribe('serviceConsumptionLoopsListCancel', (value) => {
      this.loops = JSON.parse(JSON.stringify(this.originals.loops));
    });
  }

  private getConsumptionLoops() {
    this.serviceConsumptionLoopService
      .getAll(
        '?$filter=ServiceId eq ' +
          this.service.Id +
          ' &$expand=LoopType($expand=NavisionProduct),LoopTypeService($expand=Product),LoopPositionHorizontal,LoopPositionVertical'
      )
      .then((res) => {
        this.loops = res;
        this.originals.loops = JSON.parse(JSON.stringify(res));
        const invoiceConsumptionLoopStatusList = this.loops.map((x) => (x.ServiceInvoiceId ? true : false));
        this.invoiceConsumptionLoopStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionLoopStatusList);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteLoop(loop) {
    this.serviceConsumptionLoopService
      .delete(loop.Id)
      .then((res) => {
        this.eventAggregator.publish('serviceConsumptionLoopsListReset', 0);
        this.toastService.showSuccess('service.consumptionLoopDeleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
