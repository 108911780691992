import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Models } from 'models/core';
import { LoopTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { LoopTypeService } from 'services/loop-type-service';
import { ServiceConsumptionLoopService } from 'services/service-consumption-loop-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class LoopInlineEdit {
  @bindable private service: Models.Service;
  @bindable private loop: Models.ServiceConsumptionLoop;
  @bindable private editFormVisible;
  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionLoopService: ServiceConsumptionLoopService,
    protected loopTypeService: LoopTypeService,
    protected loopTypeServiceService: LoopTypeServiceService,
    protected loopPositionHorizontalService: LoopPositionHorizontalService,
    protected loopPositionVerticalService: LoopPositionVerticalService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceConsumptionLoop) {
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure(this.service.IsInvoicingCompatible ? 'LoopTypeServiceId' : 'LoopTypeId')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('ProductionNumber')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('Amount')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(item);
    } else {
      ValidationRules.ensure('ProductionNumber').required().withMessage(this.t.tr('general.requiredField')).on(item);
    }
  }

  private async validate(item: Models.ServiceConsumptionLoop) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: item })).valid;
  }

  protected async saveLoop() {
    if (!(await this.validate(this.loop))) return;
    // remove expanded objects
    delete this.loop.LoopType;
    delete this.loop.LoopPositionHorizontal;
    delete this.loop.LoopPositionVertical;
    delete this.loop.LoopTypeService;

    this.serviceConsumptionLoopService
      .put(this.loop, this.loop.Id)
      .then(() => {
        this.eventAggregator.publish('serviceConsumptionLoopsListReset', 0);
        this.toastService.showSuccess('service.consumptionLoopSaved');
        this.editFormVisible = false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async getLoopTypeServiceService(){
    const loopTypeServiceServices = await this.loopTypeServiceService.getAll('?$expand=Product');

    loopTypeServiceServices.forEach((loopTypeServiceService) => {
      if (loopTypeServiceService.Product){
        loopTypeServiceService.Name = loopTypeServiceService.Name + " (" + loopTypeServiceService.Product.ArticleNo + ")";
      }
    })
    
    return loopTypeServiceServices
  }

  protected async getLoopTypeService(){
    const loopTypeServices = await this.loopTypeService.getAll();

    loopTypeServices.forEach((loopTypeService) => {
      if (loopTypeService.NavisionProduct){
        loopTypeService.Name = loopTypeService.Name + " (" + loopTypeService.NavisionProduct.ArticleNo + ")";
        loopTypeService.NameEn = loopTypeService.NameEn + " (" + loopTypeService.NavisionProduct.ArticleNo + ")";
        loopTypeService.NameEs = loopTypeService.NameEs + " (" + loopTypeService.NavisionProduct.ArticleNo + ")";
      }
    })
    
    return loopTypeServices
  }

  private cancel() {
    this.editFormVisible = null;
    this.eventAggregator.publish('serviceConsumptionLoopsListCancel', 0);
  }
}
