import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { NettingTypeService } from 'models';
import { Models } from 'models/core';
import { NettingTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ServiceConsumptionNettingService } from 'services/service-consumption-netting-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class NettingInlineEdit {
  @bindable private netting: Models.ServiceConsumptionNetting;
  @bindable private editFormVisible;
  @bindable private service: Models.Service;

  private nettings: NettingTypeService[] = [];
  protected selectedNetting: NettingTypeService;
  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionNettingService: ServiceConsumptionNettingService,
    protected threadTypeService: ThreadTypeService,
    protected nylonTypeService: NylonTypeService,
    protected meshSizeService: MeshSizeService,
    protected nettingTypesServiceService: NettingTypeServiceService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceConsumptionNetting) {
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure('NettingTypeId')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('Consumption')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(0)
        .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .ensure('ProductionNumber')
        .satisfies((v) => {
          if (typeof v === 'string') {
            return v?.length > 0;
          }
        })
        .withMessage(this.t.tr('general.requiredField'))
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(item);
    } else {
      ValidationRules.ensure('ProductionNumber').required().withMessage(this.t.tr('general.requiredField')).on(item);
    }
  }

  private async validate(item: Models.ServiceConsumptionNetting) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: item })).valid;
  }

  protected attached() {
    this.applyValidationRules(this.netting);
    void this.getNettings();
  }

  private async getNettings() {
    this.nettings = await this.nettingTypesServiceService.getAll(
      `?$expand=MeshSize,Material,NylonType,ThreadType,Product&?filter=Inactive neq true`
    );

    this.nettings.forEach((netting) => {
      if (netting.Product) {
        netting.Name = netting.Name + ' (' + netting.Product.ArticleNo + ')';
      }
    });

    this.selectedNetting = this.nettings.find((n) => n.Id === this.netting.NettingTypeId);
  }

  protected nettingTypeChanged({ detail }: { detail: { value: string } }) {
    const nettingTypeId = +detail.value;
    const netting = this.nettings.find((n) => n.Id === nettingTypeId);
    if (!netting) return;

    this.selectedNetting = netting;
    this.netting.MeshSizeId = netting.MeshSizeId || null;
    this.netting.NylonTypeId = netting.NylonTypeId || null;
    this.netting.ThreadTypeId = netting.ThreadTypeId || null;
    this.netting.NettingTypeId = nettingTypeId;
  }

  protected async saveNetting() {
    try {
      // remove expanded objects
      delete this.netting.ThreadType;
      delete this.netting.NylonType;
      delete this.netting.MeshSize;
      delete this.netting.NettingType;

      if (!(await this.validate(this.netting))) return;

      await this.serviceConsumptionNettingService.put(this.netting, this.netting.Id);
      this.eventAggregator.publish('serviceConsumptionNettingsListReset', 0);
      this.toastService.showSuccess('service.consumptionNettingSaved');
      this.editFormVisible = false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected cancel() {
    this.editFormVisible = null;
    this.eventAggregator.publish('serviceConsumptionNettingsListCancel', 0);
  }
}
