import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { NettingTypeService } from 'models';
import { Models } from 'models/core';
import { NettingTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ServiceConsumptionThreadService } from 'services/service-consumption-thread-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ThreadInlineEdit {
  @bindable private thread: Models.ServiceConsumptionThread;
  @bindable private editFormVisible;
  @bindable private service: Models.Service;

  private nettings: NettingTypeService[] = [];
  protected selectedNetting: NettingTypeService;
  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionThreadService: ServiceConsumptionThreadService,
    protected threadTypeService: ThreadTypeService,
    protected nylonTypeService: NylonTypeService,
    protected meshSizeService: MeshSizeService,
    protected nettingTypesServiceService: NettingTypeServiceService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceConsumptionThread) {
    if (this.service.IsInvoicingCompatible) {
      ValidationRules.ensure('NettingTypeServiceId')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('Consumption')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(0)
        .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
        .ensure('ProductionNumber')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(item);
    } else {
      ValidationRules.ensure('ProductionNumber').required().withMessage(this.t.tr('general.requiredField')).on(item);
    }
  }

  private async validate(item: Models.ServiceConsumptionThread) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: item })).valid;
  }

  protected attached() {
    void this.getNettings();
  }

  private async getNettings() {
    this.nettings = await this.nettingTypesServiceService.getAll(
      `?$expand=MeshSize,Material,NylonType,ThreadType,Product&?filter=Inactive neq true`
    );

    this.nettings.forEach((netting) => {
      if (netting.Product) {
        netting.Name = netting.Name + ' (' + netting.Product.ArticleNo + ')';
      }
    });

    this.selectedNetting = this.nettings.find((n) => n.Id === this.thread.NettingTypeServiceId);
  }

  protected nettingTypeChanged({ detail }: { detail: { value: string } }) {
    const nettingTypeId = +detail.value;
    const netting = this.nettings.find((n) => n.Id === nettingTypeId);
    if (!netting) return;

    this.selectedNetting = netting;
    this.thread.MeshSizeId = netting.MeshSizeId || null;
    this.thread.NylonTypeId = netting.NylonTypeId || null;
    this.thread.ThreadTypeId = netting.ThreadTypeId || null;
    this.thread.NettingTypeServiceId = nettingTypeId;
  }

  protected async saveThread() {
    try {
      // remove expanded objects
      delete this.thread.ThreadType;
      delete this.thread.NylonType;
      delete this.thread.MeshSize;
      delete this.thread.NettingTypeService;

      if (!(await this.validate(this.thread))) return;

      await this.serviceConsumptionThreadService.put(this.thread, this.thread.Id);
      this.eventAggregator.publish('serviceConsumptionThreadsListReset', 0);
      this.toastService.showSuccess('service.consumptionThreadSaved');
      this.editFormVisible = false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected cancel() {
    this.editFormVisible = null;
    this.eventAggregator.publish('serviceConsumptionThreadsListCancel', 0);
  }
}
