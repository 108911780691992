import { UserService } from './../../services/user-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("service-new");

@autoinject
export class ServiceNew {

  private service: Models.CreateServiceDto = new Models.CreateServiceDto();
  private originalObject: any = {};
  private saving: boolean = false;
  private extendValidity: boolean = false;

  private openServices: Array<Models.Service>;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private router: Router,
    private serviceService: ServiceService,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private userService: UserService,
    private dialogService: DialogService,
    private prompt: Prompt
  ) {
    ValidationRules
      .ensure('NetId').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('ServiceStationId').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('NetStandardId').required().withMessage(i18n.tr('general.requiredField'))
      .on(this.service);
  }

  private activate(params) {

    this.service.PlannedDeliveryDate = null;

    if (params.extendValidity === 'true') {
      this.extendValidity = true;
    }

    if (params.NetId) {
      this.service.NetId = params.NetId;
      this.originalObject.NetId = params.NetId;

      this.getOpenServices(this.service.NetId);
    }

    this.userService.getCurrentUser()
      .then((user) => {
        this.service.ServiceStationId = user.ServiceStationId;
      })
      .catch((err) => this.errorService.handleError(err));



  }

  private getOpenServices(netId: number) {
    if (!netId) {
      return;
    }

    this.serviceService.getAll(`?$filter=NetId eq ${netId} and ServiceStatusId ne 8 and ServiceStatusId ne 9`)
      .then(openServices => {
        logger.debug('openServices', openServices);
        this.openServices = openServices;
      })
      .catch(err => this.errorService.handleError(err));
  }

  private attached() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.service)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          if (this.openServices && this.openServices.length > 0) {
            this.toastService.showError('service.hasOpenServices');
          } else {
            this.createService();
          }
        }
      });
  }

  private createService() {
    this.saving = true;

    if (this.extendValidity) {
      this.service.ServiceTypeId = 2;
    }

    this.serviceService
      .createService(this.service)
      .then((res) => {
        this.originalObject = null;
        this.toastService.showSuccess('service.created');
        this.router.navigateToRoute('service-detail', { Id: res.Id, NetId: this.service.NetId });
        this.saving = false;
      })
      .catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }

  private canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    // fix dates
    this.service = this.utility.areEqualDateFix(this.service, [{ key: 'ReceivedDate' }]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'ReceivedDate' }]);

    if (!this.utility.areEqual(this.service, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue' }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

}
