import { Logger } from 'aurelia-logging';
import { logger } from '@sentry/utils';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceConsumptionRopeService } from 'services/service-consumption-rope-service';
import { ToastService } from 'services/toast-service';
import { InvoiceBadge } from 'components/badge/invoice-badge';

@autoinject
export class RopeList {
  @bindable private service: Models.Service;
  @bindable private net: Models.Net;
  @bindable private formVisible;
  @bindable private minimumBreakingStrength: number;
  @bindable private locked;
  @bindable private context;
  @bindable private invoiceConsumptionRopeStatus: string;

  private ropes: Array<Models.ServiceConsumptionRope>;
  @bindable({ defaultBindingMode: bindingMode.fromView }) private editFormVisible;
  @bindable({ defaultBindingMode: bindingMode.fromView }) private newFormVisible;
  private originals: any = {};
  private query: string;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceConsumptionRopeService: ServiceConsumptionRopeService
  ) {}

  private attached() {
    this.getConsumptionRopes();

    this.eventAggregator.subscribe('serviceConsumptionRopesListReset', (value) => {
      this.getConsumptionRopes();
    });

    this.eventAggregator.subscribe('serviceConsumptionRopesListCancel', (value) => {
      this.ropes = JSON.parse(JSON.stringify(this.originals.ropes));
    });
  }

  private getConsumptionRopes() {
    if (this.context == 'spaghetti') {
      this.query = ' and IsSpaghetti eq true &$expand=RopePlacement,RopeDimension,RopeType($expand=NavisionProduct),RopeTypeService($expand=Product)';
    } else {
      this.query = ' and IsSpaghetti ne true &$expand=RopePlacement,RopeDimension,RopeType($expand=NavisionProduct),RopeTypeService($expand=Product)';
    }

    this.serviceConsumptionRopeService
      .getAll('?$filter=ServiceId eq ' + this.service.Id + this.query)
      .then((res) => {
        this.ropes = res;
        this.originals.ropes = JSON.parse(JSON.stringify(res));
        const invoiceConsumptionRopeStatusList = this.ropes.map((x) => (x.ServiceInvoiceId ? true : false));
        this.invoiceConsumptionRopeStatus = InvoiceBadge.GetInvoiceType(invoiceConsumptionRopeStatusList);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteRope(rope) {
    this.serviceConsumptionRopeService
      .delete(rope.Id)
      .then((res) => {
        this.eventAggregator.publish('serviceConsumptionRopesListReset', 0);
        this.toastService.showSuccess('service.consumptionRopeDeleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
